import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';


const Brand = ({ title }) => (
  <StaticQuery
    query={graphql`
      query {
        brand: file(relativePath: { eq: "rabich_white.png" }) {
          childImageSharp {
            fluid(maxWidth: 550) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => (
      <Link className="header__brand" to="/" title={title}>
        <Img fluid={data.brand.childImageSharp.fluid} alt={title} />
      </Link>
    )}
  />
);

Brand.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Brand;
