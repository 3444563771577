import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

import MobileTab from './block/mobile-tab';
import Header from './header';
import Footer from './footer';
import Privacy from './privacy';
import '../styles/vendor.scss';
import '../styles/tailwind.css';
import '../styles/gatsby.css';

// @tailwind: #___gatsby

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
        {children}
        <Footer />
        <Privacy />
        <MobileTab />
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
