import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Location } from '@reach/router';
import { FaChevronDown, FaFacebookF, FaClipboardList } from 'react-icons/fa';
import { StaticQuery, Link, graphql } from 'gatsby';

import Brand from './brand';

const isActive = (check) => (check ? 'active selected' : '');

class SubNavi extends PureComponent {
  constructor(props) {
    super(props);

    this.toggle = React.createRef();
  }

  componentDidMount() {
    const { current: toggle } = this.toggle;
    toggle.addEventListener('click', this.toggleMenu);
  }

  componentWillUnmount() {
    const { current: toggle } = this.toggle;
    toggle.removeEventListener('click', this.toggleMenu);
  }

  toggleMenu = () => {
    const { current: toggle } = this.toggle;
    const { previousSibling: aDom } = toggle.previousSibling;

    if (!aDom.classList.contains('active')) {
      aDom.classList.add('active');
    } else if (aDom.classList.contains('active')) {
      aDom.classList.remove('active');
    }
  };

  render() {
    const { list, siteUrl } = this.props;
    return (
      <>
        <ul>
          {list.map((sub) => (
            <li key={sub.id}>
              <Link activeClassName="active" to={sub.url.replace(siteUrl, '')}>
                {sub.label}
              </Link>
            </li>
          ))}
        </ul>
        <i ref={this.toggle}>
          {/* <svg /> */}
          <FaChevronDown />
        </i>
      </>
    );
  }
}

SubNavi.propTypes = {
  list: PropTypes.arrayOf({}).isRequired,
  siteUrl: PropTypes.string.isRequired,
};

const MainLink = ({ url, label, siteUrl }) => {
  if (url.indexOf('#') === 0) {
    return (
      <Location>
        {({ location: { pathname } }) => (
          <span className={isActive(pathname.indexOf(url.replace('#', '/')) === 0)}>{label}</span>
        )}
      </Location>
    );
  } else if (url.indexOf(siteUrl) === -1) {
    return (
      <a href={url} rel="noopener noreferrer noopener" target="_blank">
        <span>{label}</span>
      </a>
    );
  }

  return (
    <Link to={url.replace(siteUrl, '')} activeClassName="active selected" partiallyActive>
      <span>{label}</span>
    </Link>
  );
};

MainLink.propTypes = {
  url: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  siteUrl: PropTypes.string.isRequired,
};

class Header extends PureComponent {
  constructor(props) {
    super(props);

    this.header = React.createRef();
    this.close = React.createRef();
    this.leftNavi = React.createRef();
  }

  componentDidMount() {
    const { current: close } = this.close;
    window.addEventListener('scroll', this.checkScroll);
    close.addEventListener('click', this.toggleNavi);
    this.checkScroll();
  }

  componentWillUnmount() {
    const { current: close } = this.close;
    close.addEventListener('click', this.toggleNavi);
    window.removeEventListener('scroll', this.checkScroll);
  }

  checkScroll = () => {
    const doc = document.documentElement;
    const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    const { current: header } = this.header;

    if (top && !header.classList.contains('header--active')) {
      header.classList.add('header--active');
    } else if (!top) {
      header.classList.remove('header--active');
    }
  };

  toggleNavi = () => {
    const { current: close } = this.close;
    const { current: leftNavi } = this.leftNavi;
    const { body } = document;

    if (close.classList.contains('sandwich--close')) {
      close.classList.remove('sandwich--close');
      leftNavi.classList.remove('header__navi--active');
      body.classList.remove('body__scroll--disabled');
    } else {
      close.classList.add('sandwich--close');
      leftNavi.classList.add('header__navi--active');
      body.classList.add('body__scroll--disabled');
    }
  };

  render() {
    return (
      <header className="header" ref={this.header}>
        <StaticQuery
          query={graphql`
            query NaviTop {
              wpgraphql {
                generalSettings {
                  url
                }

                menuItems(where: { location: HEADER_TOPBAR }) {
                  nodes {
                    id
                    label
                    url
                    childItems {
                      nodes {
                        id
                        label
                        url
                      }
                    }
                  }
                }
              }
              site {
                siteMetadata {
                  title
                }
              }
            }
          `}
          render={({
            wpgraphql: {
              menuItems: { nodes },
              generalSettings: { url: siteUrl },
            },
            site: { siteMetadata },
          }) => {
            if (!nodes.length) {
              return (
                <Link className="header__brand" to="/" title={siteMetadata.title}>
                  <img alt={siteMetadata.title} />
                </Link>
              );
            }

            return (
              <div className="header__container">
                <div className="sandwich" ref={this.close}>
                  <i />
                </div>
                <div className="header__navi--wrapper">
                  <div className="header__navi--brand">
                    <Brand title={siteMetadata.title} />
                  </div>
                  <ul className="header__navi header__navi--main" ref={this.leftNavi}>
                    {nodes.map(({ url, id, label, childItems }) => (
                      <li key={id}>
                        <MainLink label={label} url={url} siteUrl={siteUrl} />
                        {childItems.nodes.length > 0 && <SubNavi list={childItems.nodes} siteUrl={siteUrl} />}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            );
          }}
        />
      </header>
    );
  }
}

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
