import React from 'react';
import FooterNavi from './footer/navi';
import FooterFlat from './footer/flat';

const Footer = () => (
  <footer>
    <div className="container">
      <FooterNavi />
      <FooterFlat />
    </div>
  </footer>
);

export default Footer;
