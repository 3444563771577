import React from 'react';
import {
  FaMapMarkedAlt,
  FaPhone,
  FaEnvelope,
  FaWhatsapp,
  FaFacebook,
} from 'react-icons/fa';


const MobileTab = () => (
  <div className="mobile-tab">
    <nav>
      <a
        title="Anfart"
        rel="noopener noreferrer noopener"
        target="_blank"
        href="https://www.google.com/maps/dir/50.5244683,8.5493865/Hessenallee+22,+34613+Schwalmstadt"
      >
        <FaMapMarkedAlt />
      </a>
      <a title="Telefon" href="tel:+49066914283"><FaPhone /></a>
      <a title="E-Mail" href="mailto:info@auto-rabich.de"><FaEnvelope /></a>
      {
        /*
        <a rel="noopener noreferrer noopener" target="_blank" title="WhatsApp" href="https://wa.me/4917662812426">
          <FaWhatsapp />
        </a>
        */
      }
    </nav>
  </div>
);

export default MobileTab;
